<template>
  <div class="layout">
    <div class="layout-content">
      <el-container class="login-inner">
        <header class="login-inner-title notranslate">
          <div class="title-top" v-html="webTitle">
          </div>
          <div class="title-bottom">
            {{ webDes }}
          </div>
          <!-- <img src="../../assets/login/title.png" alt="" /> -->
        </header>
        <div class="login-inner-form">
          <div class="login-form-title">登录</div>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            class="demo-ruleForm"
            status-icon
          >
            <el-form-item prop="mobile" style="display:block">
              <vue-tel-input mode="national"
              :default-country="'CN'"
              :selectedCountry="{ iso2: 'CN' }"
              auto-default-country
              :preferred-countries="['CN']"
              placeholder="请输入手机号"
               @input="inputMobile"
               @country-changed="handleCountryChange"
               enabledCountryCode="true"
               />
              <!-- <p>{{ selectedCountry }}: {{ ruleForm.mobile }}</p>  -->
              <!-- <vue-phone-number-input v-model="ruleForm.mobile" />
              <p>Phone Number: {{ ruleForm.mobile }}</p> -->
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="ruleForm.password"
                placeholder="请输入验证码"
              />
              <el-button
                class="login-form-ver"
                @click="onSend"
                :disabled="disabled"
                >{{ smsCount ? smsCount + 's' : '获取验证码' }}</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                class="login-form-btn"
                :disabled="loginDisabled"
                v-loading="loginDisabled"
                @click="onLogin"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-container>
    </div>
    <header-vue ref="headerRef">
      <!-- <a href="javascript:;" class="login-btn">
        <img src="../../assets/login/icon.png" alt="" />
      </a> -->
      &nbsp;
    </header-vue>
    <footer class="footer">
      <el-container class="footer-container">
        <div class="footer-logo">
          <img src="../../assets/login/logo.png" alt="" />
        </div>
        <div class="footer-bd">
          <a
            class="footer-bd-text"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >粤ICP备20018311号</a
          >
          <div class="footer-bd-text">
            © 2021 KingBarin All rights reserved.
          </div>
        </div>
        <div class="footer-code">
          <img src="../../assets/login/qrcode.png" alt="" />
        </div>
      </el-container>
    </footer>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from 'vue'
import { ElMessage } from 'element-plus'
import HeaderVue from '@/layout/header.vue'
import emitter from '@/utils/mitt'
import { smsSend, login } from '@/api/app.js'
import { setToken } from '@/utils/auth'
import { useRouter, useRoute } from 'vue-router'
import Footer from '@/layout/Footer.vue'
// import { VueTelInput } from 'vue-tel-input'
// import VueTelInput from 'vue3-tel-input';
// import 'vue3-tel-input/dist/vue3-tel-input.css';
// import '../../assets/css/vue-tel-input.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import {getLanguage} from '@/lang/index'
const currentLanguage = ref(getLanguage().toUpperCase())
const webTitle = ref('专业场景数智化集成<br/>供应链服务商')
const webDes = ref('为客户提供全链条供应链解决方案')

onMounted(() => {
  pt.value = headerRef.value?.headerHeight + 'px'
  emitter.on('change-topmost', changeTopmost)

  if (currentLanguage.value == 'ZH') {
    webTitle.value = '专业场景数智化集成<br/>供应链服务商'
    webDes.value = '为客户提供全链条供应链解决方案'
  } else {
    webTitle.value = 'Professional Scene Intelligent Integrated Supply Chain Service Provider'
    webDes.value = 'Provide customers with full-chain supply chain solutions'
  }
})

const headerRef = ref(null)

const pt = ref('')

const changeTopmost = (e) => {
  pt.value = e + 'px'
}
/** login */
const ruleForm = ref({
  mobile: '',
  dial_code: '',
  password: '',
  country_code: ''
})
const rules = ref({
  mobile: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
  password: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
})
const disabled = ref(false)
const timer = ref(null)
const smsCount = ref(0)
const ruleFormRef = ref(null)
onUnmounted(() => {
  clearInterval(timer.value)
})

const inputMobile = (e) => {
  if (e.target) {
    ruleForm.value.mobile = e.target.value.replace(/[()\s-]/g, '')
  }
}

const handleCountryChange = (value) => {
  // console.log('handleCountryChange', value);
  ruleForm.value.dial_code = value.dialCode
  ruleForm.value.country_code = value.iso2
  // console.log(ruleForm.value)
}

const onSend = () => {
  // ruleForm.value.mobile = ruleForm.value.mobile.replace(/\s/g, '')
  const phoneNumber = parsePhoneNumberFromString(ruleForm.value.mobile, ruleForm.value.country_code)
  if (phoneNumber && phoneNumber.isValid()) {
    // console.log('valid')
  } else {
    // console.log('invalid')
    ElMessage({
      duration: 2000,
      message: '请输入正确的手机号',
      type: 'error'
    })
    return
  }
  ruleFormRef.value.validateField('mobile', (e) => {
    if (e) {
      disabled.value = true
      setTimeout(() => {
        disabled.value = false
      }, 100 * 1000)
      smsSend({ mobile: ruleForm.value.mobile, type_id: 3, dial_code: ruleForm.value.dial_code})
        .then((res) => {
          const { error, msg } = res
          if (error == 0) {
            ElMessage({
              message: res.msg || 'Success',
              grouping: true,
              type: 'success'
            })
            clearInterval(timer.value)
            smsCount.value = 60
            timer.value = setInterval(() => {
              smsCount.value -= 1
              if (smsCount.value <= 0) {
                clearInterval(timer.value)
                disabled.value = false
                smsCount.value = 0
              }
            }, 1000)
          } else {
            ElMessage({
              message: res.msg,
              grouping: true,
              type: 'error'
            })
            setTimeout(() => {
              disabled.value = false
            }, 3 * 1000)
          }
        })
        .catch(() => {
          setTimeout(() => {
            disabled.value = false
          }, 3 * 1000)
        })
    }
  })
}

/** login */
const loginDisabled = ref(false)
const router = useRouter()
const route = useRoute()

// router.push({ path: redirect })
const onLogin = () => {
  // console.log('mobile', ruleForm.value.mobile)
  // ruleForm.value.mobile = ruleForm.value.mobile.replace(/\s/g, '')
  // console.log('mobile', ruleForm.value.mobile, ruleForm.value.country_code)
  const phoneNumber = parsePhoneNumberFromString(ruleForm.value.mobile, ruleForm.value.country_code)
  if (phoneNumber && phoneNumber.isValid()) {
    // console.log('valid')
  } else {
    // console.log('invalid')
    ElMessage({
      duration: 2000,
      message: '请输入正确的手机号',
      type: 'error'
    })
    return
  }
  ruleFormRef.value.validate((e) => {
    if (e) {
      loginDisabled.value = true
      setTimeout(() => {
        loginDisabled.value = false
      }, 100 * 1000)

      const tmp = _.cloneDeep(ruleForm.value)
      tmp['login_type'] = 2
      login(tmp)
        .then((res) => {
          const { error, data } = res
          if (error == 0) {
            const { access_token } = data
            setToken(access_token)
            const { redirect } = route.query
            if (redirect) {
              window.location.href =
                window.location.href.split('/#')[0] + '/#' + redirect
            } else {
              router.push({ path: redirect || '/' })
            }
          } else {
            ElMessage({
              duration: 2000,
              message: res.msg,
              type: 'error'
            })
            setTimeout(() => {
              loginDisabled.value = false
            }, 1 * 1000)
          }
        })
        .catch(() => {
          setTimeout(() => {
            loginDisabled.value = false
          }, 1 * 1000)
        })
    }
  })
}
</script>

<style lang="scss" scoped>

.layout {
  padding-top: 90px;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: padding 0.3s linear;
  background: url('../../assets/login/login-bg.jpg') no-repeat center;
  background-size: cover;

  &-content {
    flex: 1;
    display: flex;
    justify-content: center;
    padding-top: 106px;
  }
}
.login {
  &-btn {
    width: 122px;
    height: 92px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-inner {
    justify-content: center;
    max-height: 350px;
    &-title {
      width: 430px;
      min-height: 188px;
      margin-right: 92px;
      // img {
      //   width: 100%;
      //   height: auto;
      // }
    }
    &-form {
      width: 512px;
      background: rgba(#e6e2e2, 0.54);
      box-shadow: 0px 5px 0px 0px rgba(7, 7, 7, 0.46);
      border-radius: 4px 4px 4px 4px;
      padding: 28px 56px 0 56px;
      :deep(.el-input) {
        height: 44px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        flex: 1;
        .el-input__inner {
          padding-left: 30px;
        }
      }
    }
  }
  &-form {
    &-title {
      font-size: 20px;
      font-weight: 500;
      color: #555555;
      line-height: 44px;
      margin-bottom: 10px;
      text-align: center;
    }
    &-ver {
      height: 44px;
      background: linear-gradient(0deg, #d1d1d1, #ededed);
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-weight: 400;
      color: #555555;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -3px;
      position: relative;
      // z-index: 1;
      min-width: 116px;
    }
    &-btn {
      width: 100%;
      height: 48px;
      font-size: 20px;
      margin-top: 10px;
    }
  }
}

.title-top {
    font-size: 38px;
    color: white;
    line-height: 1.5;
    font-weight: 550;
    border-bottom: 2px solid white;
    padding-bottom: 10px;
  }

.title-bottom {
  color: white;
  line-height: 1.5;
  padding-top: 10px;
}
.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100px;
  background-color: #ffffff;
  &-container {
    min-height: 100px;
    padding: 0 140px;
    align-items: center;
    justify-content: space-between;
  }
  &-bd {
    text-align: center;
    &-text {
      font-size: 14px;
      font-weight: 400;
      color: #555555;
      line-height: 22px;
    }
  }
  &-logo {
    width: 200px;
    min-height: 40px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &-code {
    width: 60px;
    min-height: 60px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.vue-tel-input {
  width: 100%
}
.el-form-item__content {
  background-color: white;
}
.vti__dropdown {
  background-color: white;
}
</style>
